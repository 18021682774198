import React from 'react';
import { LOGOPOSITION } from '../../../constant';

const Logo = (props) => {

    const { theme } = props;

    return (
        <>
            <div className="mb-3 navbar navbar-expand-lg" style={{ position: "sticky", zIndex: "1", top: 0, width: "100vw", height: "70px", overflow: "hidden", backgroundColor: theme?.bgColor ?? "black" }}>
                <div className="container-fluid" style={{ display: "block", textAlign: props.asset.logoPosition == LOGOPOSITION.Left ? "left" : props.asset.logoPosition == LOGOPOSITION.Center ? "center" : "right" }}>
                    <img className="navbar-brand" alt="" src={props.asset.PotraitImageUrl} height={props.isMobile ? '30px' : props.asset.logoSize + 15 + 'px'} />
                    <div className="row collapse navbar-collapse" id="navbarSupportedContent">
                        <div className="ml-3 navbar-nav me-auto mb-2 mb-lg-0">

                            {/* <div className="nav-item nav-link active" aria-current="page">Home</div>
                        <div className="nav-item nav-link active">TV</div>
                        <div className="nav-item nav-link active">Movies</div>
                        <div className="nav-item nav-link active">Web</div>
                        <div className="nav-item nav-link active">News</div> */}
                        </div>
                    </div>
                    {/* <div style={{color:"white",textAlign:"right",cursor: "pointer"}}>
                    <span >
                        <i className="fa fa-bars fa-2x"></i>
                    </span>
                </div> */}
                </div>
            </div>
        </>
    )
}

export default Logo;