import { LOGOPOSITION } from "../../../constant";

const TopMenu = ({
    ottSetting,
}) => {

    return (
        <div style={{
            height: "60px", background: ottSetting?.themeData?.appBar?.bgColor
                ?? "white", position: "fixed", top: 0, left: 0, width: "100%", paddingLeft: "10px", paddingRight: "10px", display: "flex", justifyContent: ottSetting?.logoPostion == LOGOPOSITION.Left ? "flex-start" : ottSetting?.logoPostion == LOGOPOSITION.Center ? "center" : "flex-end", alignItems: "center", color: ottSetting?.themeData?.appBar?.color
                    ?? "black",
            fontSize: ottSetting?.themeData?.appBar?.fontSize ?? "15px", fontFamily: ottSetting?.themeData?.appBar?.fontFamily ?? "Arial",
            zIndex: 100000,
            overflow: "hidden"
        }}>
            <img src={ottSetting?.logo} style={{ height: ottSetting?.logoSize ?? 33 }} />
        </div>
    );
}

export default TopMenu;
